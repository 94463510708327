import React from "react"
import styled from "styled-components"
import {
  Seo,
  Layout,
  OtherPageWrapper,
  MainWrapper,
  StyledLink,
} from "components"
import { StaticImage } from "gatsby-plugin-image"

const MenuWrapper = styled.section`
  padding: 10px 0;
  min-height: 70vh;
  background-color: var(--whiteChalk);
`

const Content = styled.div`
  display: grid;
  margin: 60px 0 30px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ContentImage = styled.div`
  min-height: 340px;
  position: relative;

  .gatsby-image-wrapper {
    width: inherit;
    height: inherit;
  }

  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    z-index: 101;
  }
`

const BackImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  img {
    border-radius: 20px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="Menu" />
    <OtherPageWrapper title="View Our Menu" />
    <MenuWrapper>
      <MainWrapper>
        <Content>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../../../gallery/IMG_20220129_102200_261.jpg"
                placeholder="blurred"
                alt="Bakery Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="bakery-items"
              >
                Bakery Items
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../../../gallery/IMG_20220109_095025_881.jpg"
                placeholder="blurred"
                alt="Food Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink backgroundColor="var(--primaryColor)" to="food-items">
                Food Items
              </StyledLink>
            </div>
          </ContentImage>
        </Content>
      </MainWrapper>
    </MenuWrapper>
  </Layout>
)

export default NotFoundPage
